import React from "react";
import { Link } from "react-router-dom";

import Logo from "../assets/logo-horizontal-ultra.png";

function Navbar() {
  return (
    <nav className="box-border h-16 w-full bg-white flex justify-around items-center shadow-md fixed top-0">
      <Link to="/">
        <img className="h-14" src={Logo} alt="logo" />
      </Link>

      <ul className="flex justify-between items-center gap-8">
        <a className="hover:underline text-sm" href="#why-us">
          {window.location.pathname === `/` ? `Why Us` : ``}
        </a>
        <a className="hover:underline text-sm" href="#benefits">
          {window.location.pathname === `/` ? `Benefits` : ``}
        </a>
        <a className="hover:underline text-sm" href="#how-it-works">
          {window.location.pathname === `/` ? `How It Works` : ``}
        </a>
        <a className="hover:underline text-sm" href="#faqs">
          {window.location.pathname === `/` ? `FAQs` : ``}
        </a>
        <Link className="hover:underline text-sm" to={"/contacts-careers"}>
          {window.location.pathname === `/` ? `Contacts & Careers` : ``}
        </Link>

        <ul className="flex justify-between items-center gap-6 ml-8">
          <Link
            className="bg-blue-800 hover:bg-orange-600 px-4 py-2 rounded-sm text-white"
            to="/agent-login"
          >
            Login
          </Link>
          <Link
            className="bg-blue-800 hover:bg-orange-600 px-4 py-2 rounded-sm text-white"
            to="/contact"
          >
            Enquire
          </Link>
        </ul>
      </ul>
    </nav>
  );
}

export default Navbar;
