import React from "react";

import TrustedCompany1 from "../../assets/trusted-1.png";
import TrustedCompany2 from "../../assets/trusted-2.png";
import TrustedCompany3 from "../../assets/trusted-3.png";
import TrustedCompany4 from "../../assets/trusted-4.png";

function TrustedBy() {
  return (
    <section id="why-us" className="flex flex-col gap-16 my-20">
      <p className="text-3xl font-bold text-center">Trusted By</p>

      <ul className="flex flex-wrap justify-evenly items-center gap-16 lg:gap-32">
        <img src={TrustedCompany1} alt="trusted-company-1" />
        <img src={TrustedCompany2} alt="trusted-company-2" />
        <img src={TrustedCompany3} alt="trusted-company-3" />
        <img src={TrustedCompany4} alt="trusted-company-4" />
      </ul>
    </section>
  );
}

export default TrustedBy;
