import React from "react";

import HIW1 from "../../assets/hiw-1.png";
import HIW2 from "../../assets/hiw-2.png";
import HIW3 from "../../assets/hiw-3.png";
import HIW4 from "../../assets/hiw-4.png";
import HIW5 from "../../assets/hiw-5.png";
import HIW6 from "../../assets/hiw-6.png";
import HIW7 from "../../assets/hiw-7.png";
import HIW8 from "../../assets/hiw-8.png";
import HIW9 from "../../assets/hiw-9.png";
import HIW10 from "../../assets/hiw-10.png";

function HowItWorks() {
  return (
    <section id="how-it-works" className="flex flex-col gap-20 my-20">
      <p className="text-3xl font-bold text-center">How It Works</p>

      <section className="grid grid-cols-2 grid-rows-5 px-4 lg:px-28">
        <img src={HIW1} alt="hiw-1" />
        <img src={HIW2} alt="hiw-2" />
        <img src={HIW3} alt="hiw-3" />
        <img src={HIW4} alt="hiw-4" />
        <img src={HIW5} alt="hiw-5" />
        <img src={HIW6} alt="hiw-6" />
        <img src={HIW7} alt="hiw-7" />
        <img src={HIW8} alt="hiw-8" />
        <img src={HIW9} alt="hiw-9" />
        <img src={HIW10} alt="hiw-10" />
      </section>
    </section>
  );
}

export default HowItWorks;
