import React from "react";

import Benefits1 from "../../assets/benefits-1.png";
import Benefits2 from "../../assets/benefits-2.png";
import Benefits3 from "../../assets/benefits-3.png";
import Benefits4 from "../../assets/benefits-4.png";
import Benefits5 from "../../assets/benefits-5.png";

function Benefits() {
  return (
    <section id="benefits" className="flex flex-col gap-20 my-20">
      <p className="text-3xl font-bold text-center">Benefits</p>

      <ul className="flex flex-wrap justify-evenly items-center gap-8 lg:gap-16 gap-y-14">
        <img src={Benefits1} alt="benefits-1" />
        <img src={Benefits2} alt="benefits-2" />
        <img src={Benefits3} alt="benefits-3" />
        <img src={Benefits4} alt="benefits-4" />
        <img src={Benefits5} alt="benefits-5" />
      </ul>
    </section>
  );
}

export default Benefits;
