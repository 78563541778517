import { useState } from "react";

import Navbar from "../components/Navbar";
import Loading from "../assets/loading.gif";

function Careers() {
  const [message, setMessage] = useState(``);
  const randomNo = ((Math.random() * 10).toFixed(0) / 2).toFixed(0);

  setTimeout(() => {
    setMessage(`Seems like there are zero openings at the moment. Please check
      back later.`);
  }, parseInt(randomNo) * 1000);

  return (
    <article>
      <Navbar />

      <section className="mt-20 px-8">
        <section id="about-us">
          <h2 className="text-xl font-bold">About Us</h2>
          <p className="italic mt-2">
            At <span className="text-blue-600 font-bold">Dwell Eazy</span>, we
            take immense pride in being a small yet dedicated team of
            hardworking and talented individuals. Our passion lies in developing
            cutting-edge real estate software solutions that empower our clients
            to thrive in the dynamic world of real estate. Despite our size, we
            are committed to delivering big results. We believe that our
            close-knit team allows us to stay agile, responsive, and deeply
            connected to the unique needs of our clients. With innovation at our
            core and a relentless pursuit of excellence, we are dedicated to
            crafting software that simplifies, streamlines, and transforms the
            way real estate professionals do business. Welcome to our world of
            innovation, where small is synonymous with mighty.
          </p>

          <p className="mt-2 italic">
            We are a 100% remote company with asynchronous work flow and agile
            methodology.
          </p>
        </section>

        <section className="mt-10" id="mail-contact">
          <h2 className="text-xl font-bold">Contacts</h2>
          <p className="font-bold mt-2">
            For business enquiries:{" "}
            <a
              className="text-blue-600 underline ml-2"
              href="mailto:darshan.gupta@dwelleazy.com"
            >
              darshan.gupta@dwelleazy.com
            </a>
          </p>
          <p className="font-bold mt-2">
            For human resource enquiries:{" "}
            <a
              className="text-blue-600 underline ml-2"
              href="mailto:careers@dwelleazy.com"
            >
              careers@dwelleazy.com
            </a>
          </p>
        </section>

        <section className="my-10" id="careers">
          <h2 className="text-xl font-bold">Open Positions</h2>
          <section className="mt-4 h-48 w-full bg-gray-200 flex justify-center items-center">
            <p className="p-4 text-center">
              {message || (
                <img className="w-8 h-8" src={Loading} alt="loading" />
              )}
            </p>
          </section>
        </section>
      </section>
    </article>
  );
}

export default Careers;
