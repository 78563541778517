import React, { useState, useRef } from "react";

import Logo from "../../assets/logo-horizontal-ultra.png";

function LoginForm() {
  const [message, setMessage] = useState(``);
  let agentId = useRef(null);
  let password = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage(`Incorrect ID or password`);
    setTimeout(() => {
      setMessage(``);
    }, 3000);
  };

  const handleSignup = () => {
    setMessage(`New agents onboarding temporarily disabled`);
    setTimeout(() => {
      setMessage(``);
    }, 3000);
  };

  return (
    <section className="h-fit w-fit lg:w-[25vw] flex flex-col items-center p-12 m-48 shadow-lg">
      <img className="w-[50%] mb-6" src={Logo} alt="logo" />

      <p className="text-lg font-bold"> Welcome</p>
      <p className="text-center">Login to Agents Dashboard</p>

      <form className="flex flex-col gap-4 mt-6">
        <input
          className="w-80 border outline-none p-2 rounded-sm"
          type="text"
          placeholder="12 Character Agent ID"
          autoComplete="off"
          ref={agentId}
        />
        <input
          className="w-80 border outline-none p-2 rounded-sm"
          type="password"
          placeholder="Password"
          autoComplete="off"
          ref={password}
        />
        <p className="h-6 text-red-500 text-sm text-center">{message}</p>

        <button
          onClick={(e) => handleSubmit(e)}
          className="bg-blue-800 hover:bg-orange-600 px-4 py-2 rounded-sm text-white"
          type="submit"
        >
          Proceed
        </button>
      </form>

      <button
        onClick={(e) => handleSignup(e)}
        className="bg-blue-800 hover:bg-orange-600 px-4 py-2 mt-8 rounded-sm text-white"
        type="button"
      >
        Sign-up
      </button>
    </section>
  );
}

export default LoginForm;
