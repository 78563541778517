import React, { useState } from "react";

import Logo from "../../assets/logo-horizontal-ultra.png";

function ContactForm() {
  const [message, setMessage] = useState(``);
  const [inputName, setInputName] = useState(``);
  const [inputAgencyName, setInputAgencyName] = useState(``);
  const [inputEmail, setInputEmail] = useState(``);
  const [inputPhoneNo, setInputPhoneNo] = useState(``);
  const [inputEnquiry, setInputEnquiry] = useState(``);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputName === ``) {
      setMessage(`Enter a valid name`);
      setTimeout(() => {
        setInputName(``);
      }, 3000);
      return;
    }

    if (inputAgencyName === ``) {
      setMessage(`Enter a valid agency name`);
      setTimeout(() => {
        setInputAgencyName(``);
      }, 3000);
      return;
    }

    if (
      inputEmail === `` ||
      !inputEmail.includes(`@`) ||
      !inputEmail.includes(`.`)
    ) {
      setMessage(`Enter a valid email ID`);
      setTimeout(() => {
        setInputEmail(``);
      }, 3000);
      return;
    }

    if (
      inputPhoneNo === `` ||
      inputPhoneNo.length < 10 ||
      inputPhoneNo.length > 12
    ) {
      setMessage(`Enter a valid phone no.`);
      setTimeout(() => {
        setInputPhoneNo(``);
      }, 3000);
      return;
    }

    if (inputEnquiry === `` || inputEnquiry.length < 30) {
      setMessage(`Enquiry field must contact at least 30 characters`);
      setTimeout(() => {
        setInputEnquiry(``);
      }, 3000);
      return;
    }

    await setTimeout(() => {
      setMessage(`Details forwarded. We'll review and get in touch soon.`);
      setInputName(``);
      setInputAgencyName(``);
      setInputEmail(``);
      setInputPhoneNo(``);
      setInputEnquiry(``);
    }, 2500);

    setTimeout(() => {
      setMessage(``);
    }, 6000);
  };

  return (
    <section className="h-fit w-[60vw] flex flex-col items-center p-8 m-14 mb-20 shadow-lg">
      <p className="text-lg font-bold">Enquiry Form</p>
      <p className="text-center">
        We automate buyer enquiry and analysis so you can save time and get more
        listings
      </p>

      <form className="flex flex-col gap-4 mt-6">
        <section className="grid grid-cols-2 grid-rows-2 gap-8">
          <div className="flex flex-col">
            <label className="text-sm" htmlFor="name">
              Your Name:
            </label>
            <input
              id="name"
              className="border py-1 px-2 outline-none"
              type="text"
              placeholder="Bob Fisher"
              autoComplete="off"
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
            />{" "}
          </div>

          <div className="flex flex-col">
            <label className="text-sm" htmlFor="name">
              Real Estate Agency Name:
            </label>
            <input
              id="name"
              className="border py-1 px-2 outline-none"
              type="text"
              placeholder="Fisher Realities"
              autoComplete="off"
              value={inputAgencyName}
              onChange={(e) => setInputAgencyName(e.target.value)}
            />{" "}
          </div>

          <div className="flex flex-col">
            <label className="text-sm" htmlFor="name">
              Email:
            </label>
            <input
              id="name"
              className="border py-1 px-2 outline-none"
              type="email"
              placeholder="example@example.com"
              autoComplete="off"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
            />{" "}
          </div>

          <div className="flex flex-col">
            <label className="text-sm" htmlFor="name">
              Phone no.:
            </label>
            <input
              id="name"
              className="border py-1 px-2 outline-none"
              type="tel"
              placeholder="(+00) 00000 00000"
              autoComplete="off"
              value={inputPhoneNo}
              onChange={(e) => setInputPhoneNo(e.target.value)}
            />{" "}
          </div>
        </section>

        <div className="flex flex-col">
          <label className="text-sm" htmlFor="enquiry">
            Enquiry Description:
          </label>
          <textarea
            className="border outline-none"
            name="enquiry"
            id="enquiry"
            cols="30"
            rows="8"
            autoComplete="off"
            value={inputEnquiry}
            onChange={(e) => setInputEnquiry(e.target.value)}
          ></textarea>
        </div>

        <p className="h-6 text-center text-sm text-green-700">{message}</p>

        <button
          className="bg-blue-800 hover:bg-orange-600 px-4 py-2 rounded-sm text-white"
          onClick={(e) => handleSubmit(e)}
          type="button"
        >
          Submit
        </button>
      </form>

      <section className="mt-12">
        <p className="text-center font-bold text-lg">
          For business enquiries, contact:{" "}
          <a
            className="underline text-blue-600 font-normal"
            href="mailto:darshan.gupta@dwelleazy.com"
          >
            darshan.gupta@dwelleazy.com
          </a>
        </p>
        <p className="mt-2 text-center font-bold text-lg">
          For career and human resource enquiries, contact:{" "}
          <a
            className="underline text-blue-600 font-normal"
            href="mailto:careers@dwelleazy.com"
          >
            careers@dwelleazy.com
          </a>
        </p>
      </section>
    </section>
  );
}

export default ContactForm;
