import React from "react";

import HeroImage from "../../assets/hero-image.png";

function Hero() {
  return (
    <section className="flex flex-col gap-6 mt-40 mb-8">
      <p className="text-center px-40 lg:px-96 text-6xl font-bold">
        Save time and get more listings
      </p>

      <p className="text-center mt-4 px-48 lg:px-96 text-xl">
        We automate the collection and analysis of enquiries so you can identify
        and convert more listing, selling and finance opportunities
      </p>

      <img className="px-40 lg:px-96" src={HeroImage} alt="hero" />
    </section>
  );
}

export default Hero;
