import { Routes, Route } from "react-router-dom";

import Home from "./Home/Home";
import AgentLogin from "./AgentLogin/AgentLogin";
import Contact from "./Contact/Contact";
import Privacy from "./Privacy";
import Conditions from "./Conditions";
import Careers from "./Careers";

function Pages() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/agent-login" element={<AgentLogin />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy-policies" element={<Privacy />} />
      <Route path="/terms-and-conditions" element={<Conditions />} />
      <Route path="/contacts-careers" element={<Careers />} />
    </Routes>
  );
}

export default Pages;
