import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

function FAQ() {
  return (
    <section id="faqs" className="flex flex-col gap-8 my-20">
      <p className="text-3xl font-bold text-center">FAQs</p>

      <Accordion className="w-[100vw] flex flex-col gap-4 px-32 lg:px-96">
        <AccordionItem className="flex flex-col gap-2 p-2 border-black border-b">
          <AccordionItemHeading>
            <AccordionItemButton className="text-lg font-bold">
              Is it really no data entry?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Yes, buyers create their own profile which integrates into your
              system so you never have to enter data.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="flex flex-col gap-2 p-2 border-black border-b">
          <AccordionItemHeading>
            <AccordionItemButton className="text-lg font-bold">
              What happens if a buyer wants to change their search criteria?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              They are provided with an email containing a maintenance link if
              they ever want to make changes.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="flex flex-col gap-2 p-2 border-black border-b">
          <AccordionItemHeading>
            <AccordionItemButton className="text-lg font-bold">
              Does the buyer have to pay anything?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>No. There is no cost for the buyer to use the service.</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="flex flex-col gap-2 p-2 border-black border-b">
          <AccordionItemHeading>
            <AccordionItemButton className="text-lg font-bold">
              Do you share the data with other agents?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              No. You own and control your own data. We do not share data with
              other agents.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="flex flex-col gap-2 p-2 border-black border-b">
          <AccordionItemHeading>
            <AccordionItemButton className="text-lg font-bold">
              What happens to finance referral opportunities uncovered?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              We do not outsource finance referrals. This is an opportunity for
              you to work with your finance partners.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="flex flex-col gap-2 p-2 border-black border-b">
          <AccordionItemHeading>
            <AccordionItemButton className="text-lg font-bold">
              What happens if a buyer has purchased or wants to opt out?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              All they have to do is reply 'opt out' to an SMS and they will be
              removed from the system.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="flex flex-col gap-2 p-2 border-black border-b">
          <AccordionItemHeading>
            <AccordionItemButton className="text-lg font-bold">
              What if our CRM does not have a compatible integration?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>We will investigate building a bespoke integration for you.</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="flex flex-col gap-2 p-2 border-black border-b">
          <AccordionItemHeading>
            <AccordionItemButton className="text-lg font-bold">
              Does the monthly price include taxes?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>Monthly price is exclusive of taxes such as GST.</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="flex flex-col gap-2 p-2 border-black border-b">
          <AccordionItemHeading>
            <AccordionItemButton className="text-lg font-bold">
              How does the predictive analytics work?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>Sorry, trade secret 🙂</p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </section>
  );
}

export default FAQ;
