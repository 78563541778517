import React from "react";

import Feature1 from "../../assets/feature-1.png";
import Feature2 from "../../assets/feature-2.png";
import Feature3 from "../../assets/feature-3.png";
import Feature4 from "../../assets/feature-4.png";
import Feature5 from "../../assets/feature-5.png";

function Features() {
  return (
    <section className="flex flex-col gap-20 my-20">
      <p className="text-3xl font-bold text-center">Features</p>

      <ul className="flex flex-wrap justify-evenly items-center gap-8 lg:gap-16 gap-y-14">
        <img src={Feature1} alt="feature-1" />
        <img src={Feature2} alt="feature-2" />
        <img src={Feature3} alt="feature-3" />
        <img src={Feature4} alt="feature-4" />
        <img src={Feature5} alt="feature-5" />
      </ul>
    </section>
  );
}

export default Features;
