import React from "react";
import { Link } from "react-router-dom";

function TryItOut() {
  return (
    <section className="h-fit w-fit bg-blue-200 flex flex-col gap-5 px-32 py-6 my-20 items-center rounded-md">
      <p className="text-3xl font-bold text-center">Try It Out</p>

      <p className="text-lg text-center">
        Have a go at the Buyer Enquiry Form and the Agent Dashboard
      </p>

      <Link
        className="bg-blue-800 hover:bg-orange-600 px-4 py-2 rounded-sm text-white"
        to="/contact"
      >
        Let's Go!
      </Link>
    </section>
  );
}

export default TryItOut;
