import React from "react";

import Navbar from "../../components/Navbar";
import Hero from "./Hero";
import TrustedBy from "./TrustedBy";
import Features from "./Features";
import Benefits from "./Benefits";
import HowItWorks from "./HowItWorks";
import TryItOut from "./TryItOut";
import FAQ from "./FAQ";
import Footer from "./Footer";

function Home() {
  return (
    <article className="flex flex-col items-center">
      <Navbar />
      <Hero />
      <TrustedBy />
      <Features />
      <Benefits />
      <HowItWorks />
      <TryItOut />
      <FAQ />
      <Footer />
    </article>
  );
}

export default Home;
