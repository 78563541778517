import React from "react";

import Navbar from "../../components/Navbar";
import LoginForm from "./LoginForm";

function AgentsLogin() {
  return (
    <article className="relative h-[100vh] w-[100vw] flex justify-center">
      <Navbar />
      <LoginForm />
    </article>
  );
}

export default AgentsLogin;
