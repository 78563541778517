import React from "react";

import Navbar from "../../components/Navbar";
import ContactForm from "./ContactForm";

function Contact() {
  return (
    <article className="relative h-[100vh] w-[100vw] flex justify-center">
      <Navbar />
      <ContactForm />
    </article>
  );
}

export default Contact;
