import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="w-full flex justify-around my-10">
      <p className="text-gray-500 text-sm">
        © 2023 • Dwell Eazy • All Rights Reserved.
      </p>

      <a href="https://www.linkedin.com/company/dwell-eazy/about/">
        <BsLinkedin className="text-xl text-blue-700" />
      </a>

      <section className="flex gap-4">
        <Link
          className="underline text-sm text-gray-500"
          to={"/privacy-policies"}
        >
          Privacy Policy
        </Link>

        <Link
          className="underline text-sm text-gray-500"
          to={"/terms-and-conditions"}
        >
          Terms & Conditions
        </Link>
      </section>
    </footer>
  );
}

export default Footer;
